exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-works-able-js": () => import("./../../../src/pages/works/able.js" /* webpackChunkName: "component---src-pages-works-able-js" */),
  "component---src-pages-works-agoric-js": () => import("./../../../src/pages/works/agoric.js" /* webpackChunkName: "component---src-pages-works-agoric-js" */),
  "component---src-pages-works-aisb-js": () => import("./../../../src/pages/works/aisb.js" /* webpackChunkName: "component---src-pages-works-aisb-js" */),
  "component---src-pages-works-billionaires-row-js": () => import("./../../../src/pages/works/billionaires-row.js" /* webpackChunkName: "component---src-pages-works-billionaires-row-js" */),
  "component---src-pages-works-biocow-js": () => import("./../../../src/pages/works/biocow.js" /* webpackChunkName: "component---src-pages-works-biocow-js" */),
  "component---src-pages-works-capsule-social-js": () => import("./../../../src/pages/works/capsule-social.js" /* webpackChunkName: "component---src-pages-works-capsule-social-js" */),
  "component---src-pages-works-celestia-js": () => import("./../../../src/pages/works/celestia.js" /* webpackChunkName: "component---src-pages-works-celestia-js" */),
  "component---src-pages-works-glassview-js": () => import("./../../../src/pages/works/glassview.js" /* webpackChunkName: "component---src-pages-works-glassview-js" */),
  "component---src-pages-works-heartn-js": () => import("./../../../src/pages/works/heartn.js" /* webpackChunkName: "component---src-pages-works-heartn-js" */),
  "component---src-pages-works-inter-protocol-js": () => import("./../../../src/pages/works/inter-protocol.js" /* webpackChunkName: "component---src-pages-works-inter-protocol-js" */),
  "component---src-pages-works-js": () => import("./../../../src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */),
  "component---src-pages-works-juno-js": () => import("./../../../src/pages/works/juno.js" /* webpackChunkName: "component---src-pages-works-juno-js" */),
  "component---src-pages-works-kleber-js": () => import("./../../../src/pages/works/kleber.js" /* webpackChunkName: "component---src-pages-works-kleber-js" */),
  "component---src-pages-works-milam-mattress-js": () => import("./../../../src/pages/works/milam-mattress.js" /* webpackChunkName: "component---src-pages-works-milam-mattress-js" */),
  "component---src-pages-works-pannon-turfgrass-js": () => import("./../../../src/pages/works/pannon-turfgrass.js" /* webpackChunkName: "component---src-pages-works-pannon-turfgrass-js" */),
  "component---src-pages-works-penumbra-js": () => import("./../../../src/pages/works/penumbra.js" /* webpackChunkName: "component---src-pages-works-penumbra-js" */),
  "component---src-pages-works-sg-1-js": () => import("./../../../src/pages/works/sg-1.js" /* webpackChunkName: "component---src-pages-works-sg-1-js" */),
  "component---src-pages-works-swisscare-js": () => import("./../../../src/pages/works/swisscare.js" /* webpackChunkName: "component---src-pages-works-swisscare-js" */),
  "component---src-pages-works-welcomech-js": () => import("./../../../src/pages/works/welcomech.js" /* webpackChunkName: "component---src-pages-works-welcomech-js" */)
}

